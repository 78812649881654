import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, OperatorFunction, throwError } from "rxjs";
import { concatMap, first, map, retryWhen, switchMap, tap } from "rxjs/operators";
import { EndpointsService } from "../endpoints/endpoints.service";
import { ErrorComponent } from "../shared/error/error.component";
import { WozGegevens } from "../model/dto/WozGegevens";

export enum SessionStatus {
    NONE,
    OK,
    EXPIRED,
    OVERFLOWED,
}

@Injectable({
    providedIn: "root",
})
export class SessionService {
    private sessionStatus$: BehaviorSubject<SessionStatus> = new BehaviorSubject<SessionStatus>(SessionStatus.NONE);

    private errorShown = false;

    readonly SESSION_EXPIRED_RETRIES = 1;

    constructor(private endpointsService: EndpointsService, private http: HttpClient, private overlay: Overlay) {
        this.sessionStatus$.subscribe((status) => {
            if (status === SessionStatus.OVERFLOWED || status === SessionStatus.EXPIRED) {
                if (!this.errorShown) {
                    this.errorShown = true;
                    const overlayRef = this.overlay.create({
                        hasBackdrop: true,
                        backdropClass: "overlay",
                        panelClass: "modal",
                    });
                    const err = new ComponentPortal(ErrorComponent);
                    const componentRef = overlayRef.attach(err);
                    let newStatus = SessionStatus.OK;
                    componentRef.instance.error = "Maximum aantal verzoeken is overschreden";

                    if (status === SessionStatus.EXPIRED) {
                        newStatus = SessionStatus.NONE;
                        componentRef.instance.error = "Uw sessie is verlopen";
                    }
                    componentRef.instance.close.subscribe(() => {
                        componentRef.destroy();
                        overlayRef.dispose();
                        this.sessionStatus$.next(newStatus);
                        this.errorShown = false;
                    });
                }
            }
        });
    }

    start(): Observable<boolean> {
        return this.http
            .post(`${this.endpointsService.wozService}/session/start`, null, {
                observe: "response" as const,
                responseType: "text",
            })
            .pipe(
                tap(() => this.sessionStatus$.next(SessionStatus.OK)),
                map((res) => !!res)
            );
    }

    checkRateLimit(): Observable<unknown> {
        return this.sessionStatus$.pipe(
            first(),
            switchMap((status: SessionStatus) => {
                switch (status) {
                    case SessionStatus.OK:
                        return of(true);
                    case SessionStatus.NONE:
                    case SessionStatus.EXPIRED:
                        return this.start();
                }
                return throwError({ sessionStatus: status });
            })
        );
    }

    catchRateLimit(): OperatorFunction<WozGegevens, WozGegevens> {
        return retryWhen(errs =>
            errs.pipe(
                concatMap((err, i) => {
                    if (err instanceof HttpErrorResponse && err.status === 429) {
                        this.sessionStatus$.next(SessionStatus.OVERFLOWED);
                    } else if (err instanceof HttpErrorResponse && err.status === 412) {
                        if (i < this.SESSION_EXPIRED_RETRIES) {
                            return this.start();
                        }
                        this.sessionStatus$.next(SessionStatus.EXPIRED);
                    }
                    return throwError(err);
                })
            )
        );
    }
}
