import { Component, OnInit } from "@angular/core";
import { forkJoin, noop, Observable, of } from "rxjs";
import { filter, map, mergeMap, share, tap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { BagGegevens } from "./model/dto/BagGegevens";
import { AdresseerbaarObjectSuggestion } from "./model/dto/AdresseerbaarObjectSuggestion";
import { BagVbosEvent } from "./model/dto/BagVbosEvent";
import { BagKadastraalObject } from "./model/dto/BagKadastraalObject";
import { Resultaat } from "./model/dto/Resultaat";
import { SearchEvent } from "./model/dto/SearchEvent";
import { Zoom } from "./model/dto/Zoom";
import { ArceringService } from "./service/arcering.service";
import { BagService } from "./service/bag.service";
import { UiService } from "./service/ui.service";
import { WozService } from "./service/woz.service";
import { SessionService } from "./service/session.service";
import { ResultaatService } from "./service/resultaat.service";
import { ResultaatMapper } from "./model/mapper/ResultaatMapper";
import { PrefixPipe } from "./shared/utils/prefix.pipe";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    backgroundLayer: Observable<string>;
    datasetOpen = false;
    mapName = environment.mapName;
    resultaat: Observable<Resultaat>;
    resultaatSuggesties: Observable<AdresseerbaarObjectSuggestion[]>;
    searchResult: Observable<[Resultaat, AdresseerbaarObjectSuggestion[]]>;
    zoomLevel: Zoom = { zoom: 0 };

    constructor(
        private arceringService: ArceringService,
        private bagService: BagService,
        private prefixPipe: PrefixPipe,
        private resultaatService: ResultaatService,
        private sessionService: SessionService,
        private ui: UiService,
        private wozService: WozService
    ) {}

    ngOnInit(): void {
        this.sessionService.start().subscribe(noop);
        this.backgroundLayer = this.ui.selectedBackground$;
    }

    onSearch(event: SearchEvent): void {
        const resultaatAndSuggestions = this.resultaatService.getResultaatAndSuggestions(event);

        this.resultaat = resultaatAndSuggestions.pipe(map(([resultaat]) => resultaat));

        this.resultaatSuggesties = resultaatAndSuggestions.pipe(map(([, suggestions]) => suggestions));

        this.searchResult = forkJoin([this.resultaat, this.resultaatSuggesties]).pipe(share());
    }

    onZoom(val: Zoom): void {
        this.zoomLevel = val;
    }

    onLookup(event: SearchEvent): void {
        this.resultaat = this.resultaatService.getResultaat(event, true);
        this.searchResult = forkJoin([this.resultaat, of([])]).pipe(share());
    }

    onSearchByVBOs(event: BagVbosEvent): void {
        const singleBag: BagGegevens = event?.bagResults?.length === 1 ? event?.bagResults[0] : null;
        this.setSuggestionsOrResult(
            this.wozService.searchByVerblijfsObjecten(event.bagResults),
            singleBag,
            event.geoFeatures
        );
    }

    onSearchByPand(bagpand: BagGegevens): void {
        this.setSuggestionsOrResult(this.wozService.searchByPandId(bagpand.identificatie), bagpand);
    }

    onSearchByKOZ(koz: BagKadastraalObject): void {
        this.setSuggestionsOrResult(
            this.wozService.searchByKadastraalPerceel(
                koz.AKRKadastraleGemeenteCodeWaarde,
                koz.sectie,
                `${koz.perceelnummer}`
            ),
            null,
            [],
            koz.geometry
        );
    }

    private setSuggestionsOrResult(
        suggestions: Observable<AdresseerbaarObjectSuggestion[]>,
        bagpand?: BagGegevens,
        pandGeometry?: Feature<Geometry>[],
        kadastraleFeatures?: Feature<Geometry>[]
    ) {
        let partialSuggesties = suggestions.pipe(tap(() => this.arceringService.clearArcering()));

        if (kadastraleFeatures) {
            partialSuggesties = partialSuggesties.pipe(
                tap(() => this.arceringService.setKadastraleFeature(kadastraleFeatures)),
                tap((suggestions) => {
                    this.bagService.markPandenByAdresseerbaarObjectIds(
                        suggestions.filter((suggest) => suggest).map((suggest) => `${suggest.adresseerbaarObjectId}`)
                    );
                })
            );
        }
        this.resultaatSuggesties = partialSuggesties.pipe(
            tap((suggestions) => {
                if (suggestions.length >= 25) {
                    this.arceerBagResult(bagpand?.geometry, pandGeometry);
                    this.bagService.arceerPandCentra(
                        suggestions
                            .map((suggestion) => suggestion.adresseerbaarObjectId?.toString())
                            .filter((suggestion) => !!suggestion)
                    );
                } else if (suggestions.length === 1 && suggestions[0].adresseerbaarObjectId && bagpand) {
                    const bagType: string = this.getBagType(suggestions[0].adresseerbaarObjectId);
                    if (bagType.startsWith("02") || bagType.startsWith("03")) {
                        this.arceerBagResult(bagpand?.geometry);
                    }
                } else if (!suggestions || suggestions.length === 0 || suggestions[0]?.adresseerbaarObjectId) {
                    this.arceerBagResult(bagpand?.geometry, pandGeometry);
                }
            }),
            share()
        );

        this.resultaat = this.resultaatSuggesties.pipe(
            filter((suggestions) => suggestions?.length <= 1),
            mergeMap((suggestions) => {
                if (suggestions.length === 1) {
                    return this.resultaatService.getResultaat(
                        {
                            wozobjectnummer: suggestions[0].id,
                            adresseerbaarobjectId: suggestions[0].adresseerbaarObjectId?.toString(),
                        },
                        false
                    );
                }
                return of(null);
            }),
            map((result) => {
                if (!result) {
                    return {
                        ...ResultaatMapper.mapBagGegevens(bagpand),
                        noresult: !bagpand,
                    };
                }
                return result;
            })
        );

        this.searchResult = forkJoin([this.resultaat, this.resultaatSuggesties]).pipe(share());
    }

    private arceerBagResult(bagFeatures: Feature<Geometry>[], pandFeatures?: Feature<Geometry>[]): void {
        this.arceringService.addToBagFeatures([
            ...(bagFeatures ? bagFeatures : []),
            ...(pandFeatures ? pandFeatures : []),
        ]);
    }

    private getBagType(adresseerbaarObjectId: number) {
        return this.prefixPipe.transform(adresseerbaarObjectId.toString(), 16, "0").slice(4);
    }
}
